import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import Container from "../common/Container";
import Button from "../common/Button";

function HeroCarouselItem({
  title,
  isTitleOnTop,
  desc,
  url,
  color,
  imagePath,
}) {
  return (
    <Container>
      <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-x-4 gap-y-8 h-full">
        <div className="mt-9">
          <div>
            {isTitleOnTop ? (
              <>
                <h1
                  className={`text-${color} text-3xl lg:text-5xl font-bold uppercase tracking-wider`}
                >
                  {title}
                </h1>
                <p className={`text-${color} text-base lg:text-lg font-light`}>
                  {desc}
                </p>
              </>
            ) : (
              <>
                <p className={`text-${color} text-base lg:text-lg font-light`}>
                  {desc}
                </p>
                <h1
                  className={`text-${color} text-3xl lg:text-5xl font-bold uppercase tracking-wider`}
                >
                  {title}
                </h1>
              </>
            )}
          </div>
          <div className="flex justify-start mt-12">
            <Button url={url} text="READ MORE" type="contained" />
          </div>
        </div>
        <div className="h-full">
          <GatsbyImage className="h-full" image={imagePath} alt={title} />
        </div>
      </div>
    </Container>
  );
}

export default HeroCarouselItem;
