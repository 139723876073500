import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Container from "../common/Container";
import Button from "../common/Button";
import ChallengeItem from "./ChallengeItem";

export const query = graphql`
  {
    allChallengeJson {
      nodes {
        title
        desc
        image {
          publicURL
        }
      }
    }
  }
`;

function Challenge() {
  const data = useStaticQuery(query);
  const challenges = data.allChallengeJson.nodes;

  return (
    <section className="bg-ink-1">
      <Container>
        <div className="pt-16 pb-[100px]">
          <div className="grid gap-4 max-w-2xl pb-12 mx-auto text-center">
            <p className="text-ink-4 text-lg lg:text-xl font-medium tracking-wide">
              What is Real Estate Market's
            </p>
            <h2 className="text-ink-5 text-4xl lg:text-5xl font-bold leading-tight">
              CHALLENGES
            </h2>
            <p className="text-ink-4 text-base lg:text-lg font-light">
              Macroprudential analysis and the monitoring of Malaysia real
              estate markets are severely hampered due to three (3) major
              challenges in real estate market:
            </p>
          </div>
          <div className="flex flex-col lg:flex-row lg:justify-evenly gap-y-14 max-w-2xl lg:max-w-none mx-auto">
            {challenges.map((challenge, index) => (
              <ChallengeItem key={index} {...challenge} />
            ))}
          </div>
          <div className="flex justify-center mt-16">
            <Button url="#solution" text="SOLUTIONS" />
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Challenge;
