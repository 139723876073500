import React from "react";

import Layout from "../common/Layout";
import Hero from "../components/Hero";
import Challenge from "../components/Challenge";
import Solution from "../components/Solution";
import PrimaryUser from "../components/PrimaryUser";
import Client from "../components/Client";
import Contact from "../components/Contact";
import VisitUs from "../components/VisitUs";
import Seo from "../common/Seo";

export default function Home({ path }) {
  return (
    <>
      <Layout path={path}>
        <Seo title="Home" />
        <Hero />
        <Challenge />
        <Solution />
        <PrimaryUser />
        {/* <Client /> */}
        <Contact />
        <VisitUs />
      </Layout>
    </>
  );
}
