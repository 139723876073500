import React from "react";

import Button from "../common/Button";

function Contact() {
  return (
    <section className="bg-[url('../assets/images/contact-bg.png')] bg-cover bg-no-repeat">
      <div className="w-10/12 md:w-8/12 xxl:w-6/12 mx-auto py-28">
        <p className="pb-5 text-ink-2 text-lg lg:text-xl font-normal">
          Have question?
        </p>
        <h2 className="pb-5 text-ink-1 text-4xl lg:text-5xl font-bold">
          LET'S TALK
        </h2>
        <p className="pb-5 text-base lg:text-lg text-ink-2 font-light">
          Drop us a message to find out more.
          <br />
          We are happy to help.
        </p>
        <div className="flex justify-start">
          <Button url="/contact-us" text="CONNECT" />
        </div>
      </div>
    </section>
  );
}

export default Contact;
