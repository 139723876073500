import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

import "swiper/css";
import "swiper/css/pagination";

import HeroCarouselItem from "./HeroCarouselItem";

export const query = graphql`
  {
    allHeroJson {
      nodes {
        title
        isTitleOnTop
        desc
        url
        color
        backgroundColor
        image {
          childImageSharp {
            gatsbyImageData(width: 715, placeholder: NONE)
          }
        }
      }
    }
  }
`;

function HeroCarousel() {
  const data = useStaticQuery(query);
  const heroItems = data.allHeroJson.nodes;

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className} w-14 lg:w-20 h-1.5 bg-ink-3 rounded-full opacity-100"></span>`;
    },
  };

  return (
    <Swiper
      modules={[Pagination, Autoplay]}
      autoplay={{ delay: 2500, disableOnInteraction: false }}
      pagination={pagination}
      slidesPerView={1}
    >
      {heroItems.map((item, index) => {
        const imagePath = getImage(item.image);

        return (
          <SwiperSlide key={index} style={{ background: item.backgroundColor }}>
            <HeroCarouselItem {...item} imagePath={imagePath} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default HeroCarousel;
