import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Container from "../common/Container";
import Button from "../common/Button";
import SolutionItem from "./SolutionItem";

export const query = graphql`
  {
    allSolutionJson {
      nodes {
        no
        title
        desc
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              blurredOptions: { width: 100 }
            )
          }
        }
      }
    }
  }
`;

function Solution() {
  const data = useStaticQuery(query);
  const solutions = data.allSolutionJson.nodes;

  return (
    <section id="solution" className="bg-accent-1">
      <Container>
        <div className="pt-16 pb-[100px]">
          <div className="grid gap-4 max-w-[470px] pb-12 mx-auto text-center">
            <p className="text-ink-2 text-lg lg:text-xl font-medium tracking-wide">
              Value Proposition
            </p>
            <h2 className="text-ink-1 text-4xl lg:text-5xl font-bold leading-tight">
              SOLUTION
            </h2>
            <p className="text-ink-2 text-base lg:text-lg font-light">
              A credible information hub for real estate industry is needed to
              tackle the problems identified
            </p>
          </div>
          <div className="grid md:grid-cols-3 gap-y-10 gap-x-6 lg:gap-x-12">
            {solutions.map((solution, index) => {
              const imagePath = getImage(solution.image);

              return (
                <SolutionItem key={index} {...solution} imagePath={imagePath} />
              );
            })}
          </div>
          <div className="flex justify-center mt-16">
            <Button url="#services" text="EXPLORE THE MAGIC" type="contained" />
          </div>
        </div>
      </Container>
    </section>
  );
}
export default Solution;
