import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

function SolutionItem({ no, title, desc, imagePath }) {
  return (
    <article className="group relative w-full h-[270px] md:h-[230px] lg:h-[380px] mx-auto">
      <div className="absolute inset-0 rounded-2xl bg-solution-dark-gradient group-hover:bg-solution-overlay mix-blend-multiply z-10"></div>
      <GatsbyImage
        imgClassName="rounded-2xl"
        image={imagePath}
        alt={title}
        className="relative w-full h-full rounded-2xl shadow-solution-card"
      />
      <div className="absolute bottom-0 w-full p-4 text-ink-1 z-20">
        <h3 className="text-xl md:text-base lg:text-2xl font-semibold uppercase">
          {no}
          <br />
          {title}
        </h3>
        <p className="hidden group-hover:block mt-2 text-sm lg:text-base font-light">
          {desc}
        </p>
      </div>
    </article>
  );
}

export default SolutionItem;
