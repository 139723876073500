import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Container from "../common/Container";
import PrimaryUserItem from "./PrimaryUserItem";

export const query = graphql`
  {
    allPrimaryUserJson {
      nodes {
        title
        list
        image {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              blurredOptions: { width: 100 }
            )
          }
        }
      }
    }
  }
`;

function PrimaryUser() {
  const data = useStaticQuery(query);
  const primaryUsers = data.allPrimaryUserJson.nodes;

  return (
    <section className="bg-ink-1">
      <Container>
        <div className="pt-16 pb-[100px]">
          <div className="grid gap-4 max-w-[505px] pb-12 mx-auto text-center">
            <p className="text-ink-4 text-lg lg:text-xl font-medium tracking-wide">
              Target Markets
            </p>
            <h2 className="text-ink-5 text-4xl lg:text-5xl font-bold leading-tight">
              PRIMARY USERS
            </h2>
            <p className="text-ink-4 text-base lg:text-lg font-light">
              Our product suite is targeted to facilitate different business
              applications for various industries
            </p>
          </div>
          <div className="grid gap-16">
            {primaryUsers.map((user, index) => {
              const imagePath = getImage(user.image);

              return (
                <PrimaryUserItem key={index} {...user} imagePath={imagePath} />
              );
            })}
          </div>
        </div>
      </Container>
    </section>
  );
}

export default PrimaryUser;
