import React from "react";

function ChallengeItem({ title, desc, image }) {
  return (
    <article className="grid grid-cols-auto grid-rows-auto md:grid-cols-12 md:grid-rows-2 lg:grid-cols-none lg:grid-rows-none gap-4 md:gap-y-0 md:gap-x-8 lg:gap-4 content-between text-center md:text-left lg:text-center lg:w-80">
      <div className="md:col-start-1 md:col-span-2 md:row-start-1 md:row-span-2 lg:col-auto lg:row-auto">
        <div className="grid justify-center items-center w-24 h-24 mx-auto rounded-full bg-accent-2">
          <img src={image.publicURL} alt={title} width={45} />
        </div>
      </div>
      <h3 className="md:col-start-3 md:col-span-10 md:row-start-1 md:row-span-1 lg:col-auto lg:row-auto text-[22px] text-accent-2 font-medium">
        {title}
      </h3>
      <p className="md:col-start-3 md:col-span-10 md:row-start-2 md:row-span-1 lg:col-auto lg:row-auto text-ink-5 lg:text-lg font-light">
        {desc}
      </p>
    </article>
  );
}

export default ChallengeItem;
