import React from "react";
import { Link } from "gatsby";
import { HiOutlineArrowSmRight } from "react-icons/hi";

function Button({ url, text, type = "outlined" }) {
  return (
    <Link
      to={url}
      className={`flex justify-center items-center gap-2 py-3 px-8 rounded-full border-2 border-link text-base font-semibold tracking-wider transition-colors duration-300 ${
        type === "outlined"
          ? "text-link hover:bg-link hover:text-ink-1"
          : "bg-link text-ink-1"
      }`}
    >
      {text}
      <HiOutlineArrowSmRight
        size={30}
        className={`${type === "outlined" ? "hover:text-ink-1" : ""}`}
      />
    </Link>
  );
}

export default Button;
