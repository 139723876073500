import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { FiCheckCircle } from "react-icons/fi";

function PrimaryUserItem({ title, list, imagePath }) {
  return (
    <article className="group grid justify-center items-center grid-flow-row-dense md:grid-cols-2 gap-y-5">
      <div className="md:group-odd:col-start-2">
        <GatsbyImage image={imagePath} alt={title} />
      </div>
      <div className="flex flex-col gap-3 md:group-odd:col-start-1 md:ml-16 lg:ml-[122px]">
        <h3 className="text-ink-5 text-3xl lg:text-4xl font-bold uppercase">
          {title}
        </h3>
        {list.map((name, index) => (
          <p
            key={index}
            className="flex items-center justify-start gap-2 text-md lg:text-lg font-light"
          >
            <FiCheckCircle />
            {name}
          </p>
        ))}
      </div>
    </article>
  );
}

export default PrimaryUserItem;
