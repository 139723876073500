import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { useStaticQuery, graphql } from "gatsby";

export const query = graphql`
  {
    site {
      siteMetadata {
        location
      }
    }
  }
`;

function VisitUs() {
  const { site } = useStaticQuery(query);

  return (
    <section className="bg-[#001B2E]">
      <div className="flex justify-between items-center w-10/12 md:w-8/12 xxl:w-6/12 mx-auto py-28 lg:py-0">
        <div>
          <h2 className="pb-5 text-ink-1 text-4xl lg:text-5xl font-bold">
            VISIT US
          </h2>
          <p className="pb-10 text-base lg:text-lg text-ink-2 font-light w-60 lg:w-[270px]">
            {site.siteMetadata.location}
          </p>
          <div className="flex flex-start">
            <a
              href={`https://www.google.com/maps?q=${site.siteMetadata.location}`}
              className="flex justify-center items-center gap-2 py-3 px-8 rounded-full border-2 border-link text-base font-semibold tracking-wider transition-colors duration-300 bg-link text-ink-1"
              target="_blank"
              rel="noreferrer"
            >
              GOOGLE MAP
              <HiOutlineArrowSmRight size={30} className="hover:text-ink-1" />
            </a>
          </div>
        </div>
        <div className="hidden lg:block">
          <StaticImage
            src="../assets/images/building.png"
            alt="Location"
            width={500}
            placeholder="none"
          />
        </div>
      </div>
    </section>
  );
}

export default VisitUs;
