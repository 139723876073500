import React from "react";

import HeroCarousel from "./HeroCarousel";

function Hero() {
  return (
    <section id="about" className="relative">
      <HeroCarousel />
    </section>
  );
}

export default Hero;
